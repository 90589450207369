import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

// Import configs and util modules
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from './../../util/validators';

// Import shared components
import { Form, Button, FieldTextInput, FieldMultiSelect, FieldSelect } from '../../components';

import css from './Portfolio.module.css';
import { householdtasks, companionship, mentalHealth, nursingSpecializedCare, technicalSupport, careType } from '../../config/constant';

// Import modules from this directory

export const PortfolioFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        formId,
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        form
      } = formRenderProps;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const subServices = [
        ...companionship,
        ...nursingSpecializedCare,
        ...householdtasks,
        ...mentalHealth,
        ...technicalSupport,
      ];
      const handleDelay = () => {
        setTimeout(() => {
          form.change("name", "");
          form.change("services", "");
          form.change("months", "");
          form.change("typeOfCare", []);
        }, 500)
      }

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                type="text"
                id={formId ? `${formId}.name` : 'name'}
                name="name"
                autoComplete="name"
                label={intl.formatMessage({
                  id: 'PortfolioForm.nameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'PortfolioForm.namePlaceholder',
                })}
              // validate={validators.required(
              //   intl.formatMessage({
              //     id: 'PortfolioForm.fieldRequired',
              //   })
              // )}
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              {/* <FieldTextInput
               type="number"
               id={formId ? `${formId}.phone` : 'phone'}
               name="phone"
               autoComplete="family-name"
               label={intl.formatMessage({
                 id: 'PortfolioForm.phoneLabel',
               })}
               placeholder={intl.formatMessage({
                 id: 'PortfolioForm.phonePlaceholder',
               })}
               validate={validators.required(
                 intl.formatMessage({
                   id: 'PortfolioForm.fieldRequired',
                 })
               )}
             /> */}
              <FieldMultiSelect
                isMulti={true}
                isRequired={true}
                id={formId ? `${formId}.typeOfCare` : 'typeOfCare'}
                name={'typeOfCare'}
                fontClass={css.fontClass}
                options={careType}
                label={intl.formatMessage({ id: 'EditListingServicesForm.typeOfCare' })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'SignupForm.fieldRequired',
                  })
                )}
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldSelect
                id={formId ? `${formId}.services` : 'services'}
                name="services"
                label={intl.formatMessage({
                  id: 'PortfolioForm.servicesLabel',
                })}
              // validate={validators.required(
              //   intl.formatMessage({
              //     id: 'SignupForm.fieldRequired',
              //   })
              // )}
              >
                <option disabled value="">
                  {intl.formatMessage({ id: 'PortfolioForm.servicesPlaceholder' })}
                </option>
                {subServices.map(type => (
                  <option key={type.key} value={type.label}>
                    {type.label}
                  </option>
                ))}
              </FieldSelect>
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                type="number"
                id={formId ? `${formId}.months` : 'months'}
                name="months"
                // autoComplete="months"
                label={intl.formatMessage({
                  id: 'PortfolioForm.monthsLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'PortfolioForm.monthsPlaceholder',
                })}
              // validate={validators.required(
              //   intl.formatMessage({
              //     id: 'PortfolioForm.fieldRequired',
              //   })
              // )}
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={!(values.name && values.typeOfCare && values.services && values.months)}
                ready={submitReady}
                onClick={handleDelay}
              >
                <FormattedMessage id="PortfolioForm.buttonText" />
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

PortfolioFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  formId: 'PortfolioForm',
};

PortfolioFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(PortfolioFormComponent);
