import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconLogin = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.84009 7.31L7.12009 6.03L5.84009 4.75"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 6.03003H7.085"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 2C8.21 2 10 3.5 10 6C10 8.5 8.21 10 6 10"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconLogin.defaultProps = { className: null };

IconLogin.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLogin;
