import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.module.css';
const SIZE_SMALL = 'small';

const IconClose = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  // if (size === SIZE_SMALL) {
  //   return (
  //     <svg className={classes} width="9" height="9" xmlns="http://www.w3.org/2000/svg">
  //       <path
  //         d="M2.175 8.396l2.482-2.482 2.482 2.482a.889.889 0 1 0 1.258-1.257L5.914 4.657l2.482-2.483A.89.89 0 0 0 7.139.917L4.657 3.4 2.175.918A.888.888 0 1 0 .917 2.174L3.4 4.657.918 7.139a.889.889 0 1 0 1.257 1.257"
  //         fillRule="evenodd"
  //       />
  //     </svg>
  //   );
  // }

  return (
    <svg
      className={classes}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9993 29.3337C23.3327 29.3337 29.3327 23.3337 29.3327 16.0003C29.3327 8.66699 23.3327 2.66699 15.9993 2.66699C8.66602 2.66699 2.66602 8.66699 2.66602 16.0003C2.66602 23.3337 8.66602 29.3337 15.9993 29.3337Z"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2266 19.7732L19.7732 12.2266"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7732 19.7732L12.2266 12.2266"
        stroke="#98A8B7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClose;
