export const typeOfUser = [
  { key: 'I-am-looking-for-senior-care-providers', label: 'I Am Looking For Senior Care Providers' },
  { key: 'I-am-looking-for-clients', label: 'I Am Looking For Clients' },
]

export const careType = [
  { key: "full-time", label: "Full-time" },
  { key: "part-time", label: "Part-time" },
  { key: "live-in", label: "Live-in" },
  { key: "live-out", label: "Live-out" },
  { key: "long-term", label: "Long-term" },
  { key: "short-term", label: "Short-term" },
  { key: "occasional", label: "Occasional" },
  { key: "start-asap", label: "Start-ASAP" },
  { key: "summer-only", label: "Summer-only" },
  { key: "tbd", label: "TBD" },
];

export const gender = [
  { key: "m", label: "Male" },
  { key: "f", label: "Female" },
  { key: "o", label: "Others" },
];

export const age = [
  { key: "50", label: "50s" },
  { key: "60", label: "60s" },
  { key: "70", label: "70s" },
  { key: "80", label: "80s" },
  { key: "90", label: "90s" },
  { key: "100", label: "100s" },
];

export const isItFor = [
  { key: "myself", label: "Myself" },
  { key: "parent-spouse", label: "Parent Spouse" },
  { key: "grandparent", label: "Grandparent" },
  { key: "relative/friend", label: "Relative/Friend" },
  { key: "other", label: "Other" },
];

export const startTime = [
  { key: "00:00", label: "00:00" },
  { key: "01:00", label: "01:00" },
  { key: "02:00", label: "02:00" },
  { key: "03:00", label: "03:00" },
  { key: "04:00", label: "04:00" },
  { key: "05:00", label: "05:00" },
  { key: "06:00", label: "06:00" },
  { key: "07:00", label: "07:00" },
  { key: "08:00", label: "08:00" },
  { key: "09:00", label: "09:00" },
  { key: "10:00", label: "10:00" },
  { key: "11:00", label: "11:00" },
  { key: "12:00", label: "12:00" },
  { key: "13:00", label: "13:00" },
  { key: "14:00", label: "14:00" },
  { key: "15:00", label: "15:00" },
  { key: "16:00", label: "16:00" },
  { key: "17:00", label: "17:00" },
  { key: "18:00", label: "18:00" },
  { key: "19:00", label: "19:00" },
  { key: "20:00", label: "20:00" },
  { key: "21:00", label: "21:00" },
  { key: "22:00", label: "22:00" },
  { key: "23:00", label: "23:00" },
];

export const endTime = [
  { key: "00:00", label: "00:00" },
  { key: "01:00", label: "01:00" },
  { key: "02:00", label: "02:00" },
  { key: "03:00", label: "03:00" },
  { key: "04:00", label: "04:00" },
  { key: "05:00", label: "05:00" },
  { key: "06:00", label: "06:00" },
  { key: "07:00", label: "07:00" },
  { key: "08:00", label: "08:00" },
  { key: "09:00", label: "09:00" },
  { key: "10:00", label: "10:00" },
  { key: "11:00", label: "11:00" },
  { key: "12:00", label: "12:00" },
  { key: "13:00", label: "13:00" },
  { key: "14:00", label: "14:00" },
  { key: "15:00", label: "15:00" },
  { key: "16:00", label: "16:00" },
  { key: "17:00", label: "17:00" },
  { key: "18:00", label: "18:00" },
  { key: "19:00", label: "19:00" },
  { key: "20:00", label: "20:00" },
  { key: "21:00", label: "21:00" },
  { key: "22:00", label: "22:00" },
  { key: "23:00", label: "23:00" },
];

export const time = [
  { key: "morning", label: "Mornings" },
  { key: "afternoons", label: "Afternoons" },
  { key: "evening", label: "Evenings" },
  { key: "overnight", label: "Overnight" },
];

export const service = [
  { key: 'companionship', label: 'Companionship' },
  { key: 'nursingSpecializedCare', label: 'Nursing/Specialized Care' },
  { key: 'householdtasks', label: 'Household Tasks' },
  { key: 'mentalHealth', label: 'Mental Health' },
  { key: 'technicalSupport', label: 'Technical support' },
];

export const companionship = [
  { key: "going-to-outings-hobbies", label: "Going to outings Hobbies", parentService: 'companionship' },
  { key: "fun-activies", label: "Fun activies", parentService: 'companionship' },
  { key: "digital", label: "Digital Companionship", parentService: 'companionship' },
  { key: "inPerson", label: "In-Person Companionship", parentService: 'companionship' },
  { key: "conversations-with-like-minded-people", label: "Conversations with like-minded people", parentService: 'companionship' },
  { key: "bathing-feeding", label: "Bathing Feeding", parentService: 'companionship' },
  { key: "administer-medication", label: "Administer medication", parentService: 'companionship' },
  { key: "massages", label: "Massages", parentService: 'companionship' },
  { key: "personal-driver", label: "Personal driver", parentService: 'companionship' },
  { key: "wheelchair-access-van", label: "Wheelchair access van", parentService: 'companionship' },
]

export const nursingSpecializedCare = [
  { key: "adult-with-disabilities", label: "Adult with disabilities", parentService: 'nursingSpecializedCare' },
  { key: "tube-feeding", label: "Tube feeding", parentService: 'nursingSpecializedCare' },
  { key: "bowel-care", label: "Bowel care", parentService: 'nursingSpecializedCare' },
  { key: "suction", label: "Suction", parentService: 'nursingSpecializedCare' },
  { key: "dressing-wounds", label: "Dressing wounds", parentService: 'nursingSpecializedCare' },
  { key: "injections", label: "Injections", parentService: 'nursingSpecializedCare' },
  { key: "dementia-care", label: "Dementia care", parentService: 'nursingSpecializedCare' },
  { key: "post-surgery-care", label: "Post-surgery care", parentService: 'nursingSpecializedCare' },
  { key: "palliative-care", label: "Palliative care", parentService: 'nursingSpecializedCare' },
  { key: "hospice-visits-and-care", label: "Hospice visits and care", parentService: 'nursingSpecializedCare' },
]

export const householdtasks = [
  { key: "housekeeping/cleaning", label: "Housekeeping/cleaning", parentService: 'householdtasks' },
  { key: "laundry", label: "Laundry", parentService: 'householdtasks' },
  { key: "preparing-meals", label: "Preparing meals", parentService: 'householdtasks' },
  { key: "errands", label: "Errands", parentService: 'householdtasks' },
  { key: "groceries", label: "Groceries", parentService: 'householdtasks' },
]

export const mentalHealth = [
  { key: "psychologist", label: "Psychologist", parentService: 'mentalHealth' },
  { key: "social-worker", label: "Social worker", parentService: 'mentalHealth' },
  { key: "joining-medical-visits", label: "Joining medical visits", parentService: 'mentalHealth' },
  { key: "providing-clarity", label: "Providing clarity", parentService: 'mentalHealth' },
  { key: "helping-with-bureaucracy", label: "Helping with bureaucracy", parentService: 'mentalHealth' },
  { key: "answering-questions", label: "Answering questions", parentService: 'mentalHealth' },
  { key: "booking-appointments", label: "Booking appointments", parentService: 'mentalHealth' },
  { key: "occupational-therapy", label: "Occupational therapy", parentService: 'mentalHealth' },
  { key: "physical-therapy", label: "Physical therapy", parentService: 'mentalHealth' },
  { key: "exercise", label: "Exercise", parentService: 'mentalHealth' },
  { key: "cognitive-stimulation", label: "Cognitive stimulation", parentService: 'mentalHealth' },
  { key: "games-and-activities", label: "Games and activities", parentService: 'mentalHealth' },
  { key: "helping-getting-around", label: "Helping getting around", parentService: 'mentalHealth' },
  { key: "household-adjustments", label: "Household adjustments", parentService: 'mentalHealth' },
];

export const technicalSupport = [
  { key: "device-setup", label: "Device setup", parentService: 'technicalSupport' },
  { key: "internet-and-browsing", label: "Internet and browsing", parentService: 'technicalSupport' },
  { key: "troubleshooting", label: "Troubleshooting", parentService: 'technicalSupport' },
  { key: "data-management", label: "Data management", parentService: 'technicalSupport' },
  { key: "technology-education", label: "Technology education", parentService: 'technicalSupport' },
];

export const days = [
  { key: "monday", label: "Monday" },
  { key: "tuesday", label: "Tuesday" },
  { key: "wednesday", label: "Wednesday" },
  { key: "thursday", label: "Thursday" },
  { key: "friday", label: "Friday" },
  { key: "saturday", label: "Saturday" },
  { key: "sunday", label: "Sunday" },
];

export const experience = [
  { key: "0-1", label: "0-1" },
  { key: "1-3", label: "1-3" },
  { key: "3-5", label: "3-5" },
  { key: "5-8", label: "5-8" },
  { key: "8-10", label: "8-10" },
  { key: "10+", label: "10+" },
];

export const languages = [
  { key: "english", label: "English" },
  { key: "french", label: "French" },
  { key: "spanish", label: "Spanish" },
  { key: "russian", label: "Russian" },
  { key: "cantonese", label: "Cantonese" },
  { key: "mandarin", label: "Mandarin" },
];

export const qualifications = [
  { key: "access to vehicle", label: "Access to vehicle" },
  { key: "drivers-license", label: "Drivers License" },
  { key: "CPR", label: "CPR" },
  { key: "first-aid", label: "First Aid" },
  { key: "sponsorship-visa", label: "Sponsorship Visa" },
  { key: "registered-Nurse(RN)", label: "Registered Nurse (RN)" },
  { key: "advanced-practical-registered-nurse", label: "Advanced practical registered nurse" },
  { key: "certified-nursing-assistant(CNA)", label: "Certified nursing assistant (CNA)" },
  { key: "licensed-practical-nurse(LPN/LVN)", label: "Licensed practical nurse (LPN/LVN)" },
  { key: "medical-assistant", label: "Medical assistant" },
  { key: "nurse-practitioner", label: "Nurse practitioner" },
  { key: "occupational-therapist (B.Sc)", label: "Occupational therapist (B.Sc)" },
  { key: "social-worker-(B.Sc)", label: "Social worker (B.Sc)" },
  { key: "social-worker-(M.Sc.)", label: "Social worker (M.Sc.)" },
  { key: "physical-therapist-(B.Sc.)", label: "Physical therapist (B.Sc.)" },
  { key: "others", label: "Others" },
]

export const packages=[
  { key: "short&sweet", label: "Short & Sweet", value:"2 hours" },
  { key: "half-day", label: "Half Day",value:"4 hours" },
  { key: "short-day", label: "Short Day",value:"6 hours" },
  { key: "regular-day", label: "Regular Day",value:"8 hours" },
  { key: "long-day", label: "Long Day",value:"10 hours" },
  { key: "extra-long-day", label: "Extra Long Day",value:"12 hours" },
  { key: "short-overnight", label: "Short Overnight",value:"4 hours" },
  { key: "long-overnight", label: "Long Overnight",value:"6 hours" },
  { key: "daily", label: "Daily",value:"14 hours" },
]













