/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useEffect } from 'react';
import { node, number, string, shape, func } from 'prop-types';
import { compose } from 'redux';

import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { Avatar, H3, IconReviewStar, LayoutWrapperSideNav } from '../../components';

import { createGlobalState } from './hookGlobalState';
import { connect, useSelector } from 'react-redux';
import css from './LayoutWrapperDashboardSideNav.module.css';
import IconReviewHalfStar from '../IconReviewHalfStar/IconReviewHalfStar';
import IconAboutUs from '../IconAboutUs/IconAboutUs';
import IconViewProfile from '../IconViewProfile/IconViewProfile';
import IconDashboard from '../IconDashboard/IconDashboard';
import IconServiceProvider from '../IconServiceProvider/IconServiceProvider';
import IconPortfolio from '../IconPortfolio/IconPortfolio';
import IconPayment from '../IconPayment/IconPayment';
import IconChangePrivacy from '../IconChangePrivacy/IconChangePrivacy';
import IconLogout from '../IconLogout/IconLogout';
import IconLogoutAccount from '../IconLogoutAccount/IconLogoutAccount';
import IconDashboard2 from '../IconDashboard2/IconDashboard2';
import { logout } from '../../ducks/auth.duck';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { LISTING_STATE_PUBLISHED } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { ensureCurrentUser, ensureUser } from '../../util/data';
import IconPayout from '../IconPayout/IconPayout';
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Add global state for tab scrolling effect
const initialScrollState = { scrollLeft: 0 };

const { useGlobalState } = createGlobalState(initialScrollState);

// Horizontal scroll animation using element.scrollTo()
const scrollToTab = (currentTab, scrollLeft, setScrollLeft) => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    // el.scrollIntoView doesn't work with Safari and it considers vertical positioning too.
    // This scroll behaviour affects horizontal scrolling only
    // and it expects that the immediate parent element is scrollable.
    const parent = el.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const maxScrollDistance = parent.scrollWidth - parentRect.width;

    const hasParentScrolled = parent.scrollLeft > 0;
    const scrollPositionCurrent = hasParentScrolled ? parent.scrollLeft : scrollLeft;

    const tabRect = el.getBoundingClientRect();
    const diffLeftBetweenTabAndParent = tabRect.left - parentRect.left;
    const tabScrollPosition = parent.scrollLeft + diffLeftBetweenTabAndParent;
    const scrollPositionNew =
      tabScrollPosition > maxScrollDistance
        ? maxScrollDistance
        : parent.scrollLeft + diffLeftBetweenTabAndParent;

    const needsSmoothScroll = scrollPositionCurrent !== scrollPositionNew;
    if (!hasParentScrolled || (hasParentScrolled && needsSmoothScroll)) {
      // Ensure that smooth scroll animation uses old position as starting point after navigation.
      parent.scrollTo({ left: scrollPositionCurrent });
      // Scroll to new position
      parent.scrollTo({ left: scrollPositionNew, behavior: 'smooth' });
    }
    // Always keep track of new position (even if smooth scrolling is not applied)
    setScrollLeft(scrollPositionNew);
  }
};

const LayoutWrapperDashboardSideNavComponent = props => {
  const {
    onLogout,
    scrollingDisabled,
    user,
    userShowError,
    queryListingsError,
    reviews,
    queryReviewsError,
  } = props;
  const [scrollLeft, setScrollLeft] = useGlobalState('scrollLeft');
  const currentUser = ensureCurrentUser(useSelector(state => state?.user?.currentUser)) || {};
  // const profileUser = ensureUser(user);
  //   const isCurrentUser =
  //     currentUser.id && profileUser.id && currentUser.id.uuid === profileUser.id.uuid;
  // const avatarUser = isCurrentUser ? currentUser : user;
  const currentUserHasListings = useSelector(state => state?.user?.currentUserHasListings);
  const currentUserListing = useSelector(state => state?.user?.currentUserListing);

  const id = currentUserListing && currentUserListing?.id?.uuid || "";

  useEffect(() => {
    const { currentTab, viewport } = props;
    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');
    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasHorizontalTabLayout || hasFontsLoaded) {
      scrollToTab(currentTab, scrollLeft, setScrollLeft);
    }
    return () => {
      // Update scroll position when unmounting
      // const el = document.querySelector(`#${currentTab}Tab`);
      //  setScrollLeft(el.parentElement.scrollLeft);
    };
  });

  // console.log({ id : currentUserListing?.id?.uuid, slug: slug, type: "edit", tab:"location" }, '^^^  ^^^ => { id : currentUserListing?.id?.uuid, slug: slug, type: "edit", tab:"location" }');

  const { currentTab } = props;
  
  const tabs = [
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.viewProfile" />,
      icon: <IconViewProfile />,
      selected: currentTab === 'ViewProfile',
      id: 'ViewProfile',
      linkProps: {
        name: 'ProfilePage',
        params: { id: currentUser?.id?.uuid },
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.dashboard" />,
      icon: <IconDashboard2 />,
      selected: currentTab === 'Dashboard',
      id: 'Dashboard',
      linkProps: {
        name: 'DashboardPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.service" />,
      icon: <IconServiceProvider />,
      selected: currentTab === 'Services',
      id: 'Services',
      linkProps:
        currentUserHasListings && currentUserListing?.attributes?.state === LISTING_STATE_PUBLISHED
          ? {
              name: 'EditListingPage',
              params: {
                id,
                slug: createSlug(currentUserListing?.attributes?.title),
                type: LISTING_PAGE_PARAM_TYPE_EDIT,
                tab: 'location',
              },
            }
          : currentUserListing &&
            currentUserListing?.attributes?.state === LISTING_PAGE_PARAM_TYPE_DRAFT
          ? {
              name: 'EditListingPage',
              params: {
                id,
                slug: createSlug(currentUserListing?.attributes?.title),
                type: LISTING_PAGE_PARAM_TYPE_DRAFT,
                tab: 'location',
              },
            }
          : {
              name: 'NewListingPage',
            },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.portfolio" />,
      icon: <IconPortfolio />,
      selected: currentTab === 'Portfolio',
      id: 'Portfolio',
      linkProps: {
        name: 'PortfolioPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.payment" />,
      icon: <IconPayment />,
      selected: currentTab === 'PaymentMethodsPage',
      id: 'PaymentMethodsPage',
      linkProps: {
        name: 'PaymentMethodsPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.payout" />,
      icon: <IconPayout />,
      selected: currentTab === 'StripePayoutPage',
      id: 'StripePayoutPageTab',
      linkProps: {
        name: 'StripePayoutPage',
      },
    },
    {
      text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.changePrivacy" />,
      icon: <IconChangePrivacy />,
      selected: currentTab === 'PrivacyChange',
      id: 'PasswordChangePageTab',
      linkProps: {
        name: 'PasswordChangePage',
      },
    },
    // {
    //   text: <FormattedMessage onClick={onLogout} id="LayoutWrapperAccountSettingsSideNav.logout" />,
    //   icon: <IconLogoutAccount />,
    //   selected: currentTab === 'ManageListingsPage',
    //   id: 'ManageListingsPage',
    //   linkProps: {
    //     name: 'LandingPage',
    //   },
    // },
  ];

  return (
    <>
      <div className={css.profileSection}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        <H3>{currentUser?.attributes?.profile?.publicData?.name}</H3>
        <div className={css.reviewSec}>
          <div className={css.reviewStars}>
            <IconReviewStar />
            <IconReviewStar />
            <IconReviewStar />
            <IconReviewStar />
            <IconReviewHalfStar />
          </div>
          <p>4.84 (14 Reviews)</p>
        </div>
      </div>
       {currentUser?.id ? ( 
      <LayoutWrapperSideNav isDashboardSidetab={true} tabs={tabs} onLogout={onLogout} />
       ) : null} 
    </>
  );
};

LayoutWrapperDashboardSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperDashboardSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,
  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
  onLogout: func.isRequired,
};

const mapStateToProps = state => {
  //   // Topbar needs isAuthenticated
  //   const { isAuthenticated, logoutError, authScopes } = state.auth;
  //   // Topbar needs user info.
  //   const {
  //     currentUser,
  //     currentUserHasListings,
  //     currentUserHasOrders,
  //     currentUserNotificationCount: notificationCount,
  //     sendVerificationEmailInProgress,
  //     sendVerificationEmailError,
  //   } = state.user;
  //   const hasGenericError = !!(logoutError || hasCurrentUserErrors(state));
  //   return {
  //     authInProgress: authenticationInProgress(state),
  //     currentUser,
  //     currentUserHasListings,
  //     currentUserHasOrders,
  //     notificationCount,
  //     isAuthenticated,
  //     authScopes,
  //     sendVerificationEmailInProgress,
  //     sendVerificationEmailError,
  //     hasGenericError,
  //   };
  const {
    userId,
    userShowError,
    queryListingsError,
    reviews,
    queryReviewsError,
  } = state.ProfilePage;
  const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);
  const user = userMatches.length === 1 ? userMatches[0] : null;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    user,
    userShowError,
    queryListingsError,
    reviews,
    queryReviewsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onLogout: historyPush => dispatch(logout(historyPush)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671

const LayoutWrapperDashboardSideNav = compose(
  withViewport,
  connect(mapStateToProps, mapDispatchToProps)
)(LayoutWrapperDashboardSideNavComponent);

export default LayoutWrapperDashboardSideNav;
