import React, { useEffect, useRef } from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { LayoutWrapperDashboardSideNav, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import DashboardForm from './DashboardForm';
import { withViewport } from '../../util/contextHelpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { bool, arrayOf, number, shape } from 'prop-types';
import css from './DashboardPage.module.css';
import classNames from 'classnames';
import IconCross from '../../components/IconCross/IconCross';
import IconToggleMenu from '../../components/IconToggleMenu/IconToggleMenu';
const DashboardPageComponent = props => {
  const { viewport } = props;
  const isMobileLayout = viewport?.width && viewport?.width < 768;
  const { scrollingDisabled } = props;
  const [show, setShow] = React.useState(false);
  const sidebarRef = useRef(null);
  const handleClickOutside = event => {
    if (event.target !== sidebarRef.current) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <TopbarContainer />

      <div className={css.editListingPageWrapper}>
        <div
          ref={sidebarRef}
          className={classNames(css.sidebar, show && isMobileLayout ? css.sidebarMobile : null)}
        >
          <LayoutWrapperDashboardSideNav currentTab="Dashboard" />
        </div>
        <div className={css.mainContent}>
          <div className={css.pageTitle}>
            Dashboard
            <span onClick={() => setShow(!show)}>
              {!show ? (
                <IconToggleMenu className={css.menuIcon} />
              ) : (
                <IconCross rootClassName={css.closeIcon} />
              )}
            </span>
          </div>
          <DashboardForm />
        </div>
      </div>
      <FooterComponent />
    </Page>
  );
};

DashboardPageComponent.defaultProps = {};

DashboardPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const DashboardPage = compose(
  connect(mapStateToProps),
  injectIntl,
  withViewport
)(DashboardPageComponent);

export default DashboardPage;
