import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconPayment = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="5" width="24" height="3" fill="#19CCFF" />
      <rect
        x="0.75"
        y="0.75"
        width="23.5"
        height="18.5"
        rx="3.25"
        stroke="#2E3599"
        strokeWidth="1.5"
      />
    </svg>
  );
};

IconPayment.defaultProps = { className: null };

IconPayment.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPayment;
