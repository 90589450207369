import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconEditWork = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.735 2.1L2.94584 7.16917C2.765 7.36167 2.59 7.74083 2.555 8.00333L2.33917 9.89333C2.26334 10.5758 2.75334 11.0425 3.43 10.9258L5.30834 10.605C5.57084 10.5583 5.93834 10.3658 6.11917 10.1675L10.9083 5.09833C11.7367 4.22333 12.11 3.22584 10.8208 2.00667C9.5375 0.799169 8.56334 1.225 7.735 2.1Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.93584 2.94584C7.18667 4.55584 8.49334 5.78667 10.115 5.95"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 12.8333H12.25"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconEditWork.defaultProps = { className: null };

IconEditWork.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconEditWork;
