import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

// Import configs and util modules
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from './../../util/validators';

// Import shared components
import { Form, Button, FieldTextInput } from '../../components';

import css from './ContactUs.module.css';

// Import modules from this directory

export const ContactUsFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        formId,
        autoFocus,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        values,
      } = formRenderProps;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes}>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                type="text"
                id={formId ? `${formId}.name` : 'name'}
                name="name"
                // autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'ContactUsForm.nameLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ContactUsForm.namePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'ContactUsForm.fieldRequired',
                  })
                )}
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                // autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'ContactUsForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ContactUsForm.emailPlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'ContactUsForm.fieldRequired',
                  })
                )}
              />
            </div>
          </div>

          <div className={css.formRow}>
            <div className={css.formFld}>
              <FieldTextInput
                type="textarea"
                id={formId ? `${formId}.message` : 'message'}
                name="message"
                // autoComplete="family-name"
                label={intl.formatMessage({
                  id: 'ContactUsForm.messageLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'ContactUsForm.messagePlaceholder',
                })}
                validate={validators.required(
                  intl.formatMessage({
                    id: 'ContactUsForm.fieldRequired',
                  })
                )}
              />
            </div>
          </div>
          <div className={css.formRow}>
            <div className={css.formFld}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
                onClick={() => {
                  handleSubmit();
                }}
              >
                <FormattedMessage id="ContactUsForm.buttonText" />
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

ContactUsFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  formId: 'ContactUsForm',
};

ContactUsFormComponent.propTypes = {
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(ContactUsFormComponent);
