import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconDashboard = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.4" y="0.4" width="4.4" height="12.2" stroke="#2e3599" strokeWidth="0.8" />
      <rect x="6.9" y="0.4" width="4.4" height="7" stroke="#155EBD" strokeWidth="0.8" />
      <rect x="6.9" y="9.49998" width="4.4" height="3.1" stroke="#2e3599" strokeWidth="0.8" />
    </svg>
  );
};

IconDashboard.defaultProps = { className: null };

IconDashboard.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDashboard;
