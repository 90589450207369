import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconTrash = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="18"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0925 0.193333L8.625 0.666667H10.5C10.9125 0.666667 11.25 0.966667 11.25 1.33333C11.25 1.7 10.9125 2 10.5 2H1.5C1.0875 2 0.75 1.7 0.75 1.33333C0.75 0.966667 1.0875 0.666667 1.5 0.666667H3.375L3.9075 0.193333C4.0425 0.0733333 4.2375 0 4.4325 0H7.5675C7.7625 0 7.9575 0.0733333 8.0925 0.193333ZM1.5 10.6667C1.5 11.4 2.175 12 3 12H9C9.825 12 10.5 11.4 10.5 10.6667V4C10.5 3.26667 9.825 2.66667 9 2.66667H3C2.175 2.66667 1.5 3.26667 1.5 4V10.6667ZM3.75 4H8.25C8.6625 4 9 4.3 9 4.66667V10C9 10.3667 8.6625 10.6667 8.25 10.6667H3.75C3.3375 10.6667 3 10.3667 3 10V4.66667C3 4.3 3.3375 4 3.75 4Z"
        fill="white"
      />
    </svg>
  );
};

IconTrash.defaultProps = { className: null };

IconTrash.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconTrash;
