import React, { useEffect, useRef } from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';

import LayoutComposer from '../LayoutComposer';
import LayoutWrapperAccountSettingsSideNav from './LayoutWrapperAccountSettingsSideNav';

import css from './LayoutSideNavigation.module.css';
import IconCross from '../../IconCross/IconCross';
import IconToggleMenu from '../../IconToggleMenu/IconToggleMenu';
import NamedLink from '../../NamedLink/NamedLink';
import IconEdit from '../../IconEdit/IconEdit';

// Commonly used layout
const LayoutSideNavigation = props => {
  const {
    className,
    rootClassName,
    containerClassName,
    mainColumnClassName,
    sideNavClassName,
    children,
    topbar: topbarContent,
    footer: footerContent,
    sideNav: sideNavContent,
    useAccountSettingsNav,
    currentPage,
    viewport,
    ...rest
  } = props;
  const isMobileLayout = viewport?.width && viewport?.width < 768;
  const classes = classNames(rootClassName || css.root, className);
  const containerClasses = containerClassName || css.editListingPageWrapper;

  // TODO: since responsiveAreas are still experimental,
  //       we don't separate "aside" through layoutComposer
  const layoutAreas = `
    topbar
    main
    footer
  `;
  const [show, setShow] = React.useState(false);
  const sidebarRef = useRef(null);
  const handleClickOutside = event => {
    if (event.target !== sidebarRef.current) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <LayoutComposer areas={layoutAreas} className={classes} {...rest}>
      {layoutProps => {
        const { Topbar, Main, Footer } = layoutProps;
        return (
          <>
            <Topbar as="header" className={css.topbar}>
              {topbarContent}
            </Topbar>
            <Main as="div" className={containerClasses}>
              <aside
                ref={sidebarRef}
                className={classNames(
                  css.sidebar,
                  sideNavClassName,
                  show && isMobileLayout ? css.sidebarMobile : null
                )}
              >
                {useAccountSettingsNav ? (
                  <LayoutWrapperAccountSettingsSideNav currentPage={currentPage} />
                ) : null}
                {sideNavContent}
              </aside>
              <main className={classNames(css.mainContent, mainColumnClassName)}>
                <div className={css.pageTitle}>
                  <span>
                    Profile{' '}
                    <NamedLink className={css.editProfile} name="ProfileSettingsPage">
                      <IconEdit />
                    </NamedLink>
                  </span>
                  <span onClick={() => setShow(!show)}>
                    {!show ? (
                      <IconToggleMenu className={css.menuIcon} />
                    ) : (
                      <IconCross rootClassName={css.closeIcon} />
                    )}
                  </span>
                </div>
                {children}
              </main>
            </Main>
            <Footer>{footerContent}</Footer>
          </>
        );
      }}
    </LayoutComposer>
  );
};

LayoutSideNavigation.displayName = 'LayoutSideNavigation';

LayoutSideNavigation.defaultProps = {
  className: null,
  rootClassName: null,
  sideNav: null,
  footer: null,
  useAccountSettingsNav: false,
  currentPage: null,
};

LayoutSideNavigation.propTypes = {
  className: string,
  rootClassName: string,
  children: node.isRequired,
  topbar: node.isRequired,
  sideNav: node,
  footer: node,
  useAccountSettingsNav: bool,
  currentPage: string,
};

export default LayoutSideNavigation;
