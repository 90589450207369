import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconChangePrivacy = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2385 2.60133L6.41685 4.79466C5.07518 5.29633 3.97852 6.88299 3.97852 8.30633V16.9747C3.97852 18.3513 4.88852 20.1597 5.99685 20.988L11.0135 24.733C12.6585 25.9697 15.3652 25.9697 17.0102 24.733L22.0268 20.988C23.1352 20.1597 24.0452 18.3513 24.0452 16.9747V8.30633C24.0452 6.87133 22.9485 5.28466 21.6068 4.78299L15.7852 2.60133C14.7935 2.23966 13.2068 2.23966 12.2385 2.60133Z"
        stroke="#2E3599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9998 14.5837C15.2885 14.5837 16.3332 13.539 16.3332 12.2503C16.3332 10.9617 15.2885 9.91699 13.9998 9.91699C12.7112 9.91699 11.6665 10.9617 11.6665 12.2503C11.6665 13.539 12.7112 14.5837 13.9998 14.5837Z"
        stroke="#19CCFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14.583V18.083"
        stroke="#19CCFF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconChangePrivacy.defaultProps = { className: null };

IconChangePrivacy.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconChangePrivacy;
