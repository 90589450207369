import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconPayout = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="25"
      height="25"
      viewBox="0 0 708 674"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M534.473 267.14C554 247.614 554 216.187 554 153.333C554 90.4793 554 59.0523 534.473 39.5263M534.473 267.14C514.947 286.667 483.52 286.667 420.667 286.667H287.333C224.479 286.667 193.052 286.667 173.526 267.14M534.473 39.5263C514.947 20 483.52 20 420.667 20H287.333C224.479 20 193.052 20 173.526 39.5263M173.526 39.5263C154 59.0523 154 90.4793 154 153.333C154 216.187 154 247.614 173.526 267.14"
        stroke="#19CCFF"
        strokeWidth="40"
      />
      <path
        d="M387.333 153.333C387.333 171.743 372.41 186.667 354 186.667C335.59 186.667 320.667 171.743 320.667 153.333C320.667 134.924 335.59 120 354 120C372.41 120 387.333 134.924 387.333 153.333Z"
        stroke="#19CCFF"
        strokeWidth="40"
      />
      <path
        d="M554 120C498.77 120 454 75.2283 454 20"
        stroke="#19CCFF"
        strokeWidth="40"
        strokeLinecap="round"
      />
      <path
        d="M554 186.667C498.77 186.667 454 231.438 454 286.667"
        stroke="#19CCFF"
        strokeWidth="40"
        strokeLinecap="round"
      />
      <path
        d="M154 120C209.228 120 254 75.2283 254 20"
        stroke="#19CCFF"
        strokeWidth="40"
        strokeLinecap="round"
      />
      <path
        d="M154 186.667C209.228 186.667 254 231.438 254 286.667"
        stroke="#19CCFF"
        strokeWidth="40"
        strokeLinecap="round"
      />
      <path
        d="M120.667 599.613H195.998C229.693 599.613 263.751 603.123 296.543 609.88C354.553 621.83 415.627 623.277 474.23 613.793C503.127 609.113 531.533 601.963 557.25 589.55C580.463 578.347 608.897 562.553 627.997 544.863C647.07 527.2 666.933 498.29 681.03 475.69C693.12 456.313 687.273 432.54 668.15 418.1C646.91 402.063 615.39 402.067 594.153 418.11L533.913 463.613C510.567 481.25 485.067 497.483 454.687 502.33C451.033 502.913 447.207 503.443 443.213 503.907M443.213 503.907C442.01 504.047 440.793 504.18 439.56 504.307M443.213 503.907C448.073 502.867 452.897 499.867 457.427 495.917C478.867 477.203 480.22 445.667 461.617 424.77C457.303 419.923 452.25 415.88 446.637 412.53C353.39 356.913 208.314 399.273 120.667 461.43M443.213 503.907C441.997 504.167 440.777 504.307 439.56 504.307M439.56 504.307C422.113 506.097 401.707 506.56 379.06 504.42"
        stroke="#2E3599"
        strokeWidth="40"
        strokeLinecap="round"
      />
      <path
        d="M120.667 436.667C120.667 409.052 98.281 386.667 70.6667 386.667C43.0525 386.667 20.6667 409.052 20.6667 436.667V603.333C20.6667 630.948 43.0525 653.333 70.6667 653.333C98.281 653.333 120.667 630.948 120.667 603.333V436.667Z"
        stroke="#2E3599"
        strokeWidth="40"
      />
    </svg>
  );
};

IconPayout.defaultProps = { className: null };

IconPayout.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconPayout;
