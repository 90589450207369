import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconReviewHalfStar.module.css';

const IconReviewHalfStar = props => {
  const { className, rootClassName, isFilled } = props;
  const filledOrDefault = isFilled ? css.filled : css.root;
  const classes = classNames(rootClassName || filledOrDefault, className);

  return (
    <svg
      className={classes}
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9787 7.57202L23.8353 8.16702C25.082 8.26619 25.5778 9.82452 24.6287 10.6462L19.4295 15.1512L20.9878 21.852C21.2712 23.0704 19.9537 24.0337 18.877 23.382L12.9978 19.8262L7.11868 23.3679C6.04201 24.0195 4.72451 23.0562 5.00784 21.8379L6.56618 15.1512L1.36701 10.6462C0.417845 9.82452 0.927845 8.26619 2.17451 8.16702L9.01701 7.58619L11.6945 1.26785C12.1762 0.120352 13.8195 0.120352 14.3012 1.26785L16.9787 7.57202ZM12.9978 4.00202V17.177L18.3387 20.407L16.922 14.3437L21.6253 10.2637L15.4203 9.72535L12.9978 4.00202Z"
        fill="#FDCC4A"
      />
    </svg>
  );
};

IconReviewHalfStar.defaultProps = { className: null, rootClassName: null, isFilled: false };

const { bool, string } = PropTypes;

IconReviewHalfStar.propTypes = {
  className: string,
  rootClassName: string,
  isFilled: bool,
};

export default IconReviewHalfStar;
