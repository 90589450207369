import React from 'react';
import classNames from 'classnames';
import css from './WelcomePage.module.css';
import IconCheckCircle from '../../components/IconCheckCircle/IconCheckCircle';
import { Button, LayoutSingleColumn, NamedLink, Page } from '../../components';
import backgroundCheck from './../../assets/bg-check.png';
import reviews from './../../assets/reviews.png';
import rating from './../../assets/rating.png';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { bool, func, shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';

import authimg from '../../assets/login.png';
const WelcomePageComponent = props => {
  const { intl, scrollingDisabled } = props;
  const homeLink = (
    <NamedLink name="LandingPage" className={css.recoveryLink}>
      <FormattedMessage id="WelcomePage.clickLinkText" />
    </NamedLink>
  );
  return (
    <Page
      title={intl.formatMessage({
        id: 'WelcomePage.title',
      })}
      scrollingDisabled={scrollingDisabled}
      referrer="origin"
    >
      <LayoutSingleColumn
        mainColumnClassName={css.layoutWrapperMain}
        topbar={<TopbarContainer />}
        footer={<FooterContainer />}
      >
        <div className={css.authWrapper}>
          <div className={css.content}>
            <div className={classNames(css.signupStep, css.welcomeStep)}>
              <div className={css.signupStepHead}>
                <h2>
                  <span><FormattedMessage id='WelcomePage.welcome' /></span> <span><FormattedMessage id='WelcomePage.name' /></span>
                </h2>
                <h4>
                  <FormattedMessage id='WelcomePage.result' /> <IconCheckCircle />
                </h4>
                <p><FormattedMessage id='WelcomePage.statement' values={{ homeLink }} /></p>
              </div>
              <div className={css.browserCareGiverSec}>
                <h2><FormattedMessage id='WelcomePage.block' /></h2>
                <div className={css.careGiverBlock}>
                  <div className={css.careGiver}>
                    <img src={backgroundCheck} alt="check" />
                    <p><FormattedMessage id='WelcomePage.block1' /></p>
                  </div>
                  <div className={css.careGiver}>
                    <img src={reviews} alt="check" />
                    <p><FormattedMessage id='WelcomePage.block2' /></p>
                  </div>
                  <div className={css.careGiver}>
                    <img src={rating} alt="check" />
                    <p><FormattedMessage id='WelcomePage.block3' /></p>
                  </div>
                </div>
              </div>
              <div className={css.browserBtnSec}>
                <Button className={css.nextBtn} type="button">
                  <FormattedMessage id='WelcomePage.browseCareGiver' />
                </Button>
              </div>
            </div>
          </div>
          <div className={css.imgSec}>
            <img src={authimg} alt="image" />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};
WelcomePageComponent.defaultProps = {
  currentUser: null,
  verificationError: null,
};

WelcomePageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // submitVerification: func.isRequired,
  // isVerified: bool,
  // emailVerificationInProgress: bool.isRequired,
  // verificationError: propTypes.error,

  // // from withRouter
  // location: shape({
  //   search: string,
  // }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  // const { isVerified, verificationError, verificationInProgress } = state.emailVerification;
  return {
    // isVerified,
    // verificationError,
    // emailVerificationInProgress: verificationInProgress,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  // submitVerification: ({ verificationToken }) => {
  //   return dispatch(verify(verificationToken));
  // },
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const WelcomePage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(WelcomePageComponent);

export default WelcomePage;
