import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconServiceProvider = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6665 28.875H9.33317C3.99984 28.875 2.6665 27.5 2.6665 22V11C2.6665 5.5 3.99984 4.125 9.33317 4.125H22.6665C27.9998 4.125 29.3332 5.5 29.3332 11V22C29.3332 27.5 27.9998 28.875 22.6665 28.875Z"
        stroke="#2E3599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6665 11H25.3332"
        stroke="#2E3599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 16.5H25.3333"
        stroke="#2E3599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6665 22H25.3332"
        stroke="#2E3599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 15.5234C12.6661 15.5234 13.7466 14.4091 13.7466 13.0346C13.7466 11.6601 12.6661 10.5459 11.3333 10.5459C10.0004 10.5459 8.91992 11.6601 8.91992 13.0346C8.91992 14.4091 10.0004 15.5234 11.3333 15.5234Z"
        stroke="#19CCFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9998 22.4537C15.8132 20.4599 14.2798 18.8924 12.3465 18.7137C11.6798 18.6449 10.9998 18.6449 10.3198 18.7137C8.3865 18.9062 6.85317 20.4599 6.6665 22.4537"
        stroke="#19CCFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconServiceProvider.defaultProps = { className: null };

IconServiceProvider.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconServiceProvider;
