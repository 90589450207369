import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage } from '../../util/reactIntl';
// Import shared components
import { H3, Page } from '../../components';

// Import modules from this directory
import css from './ContactUs.module.css';
import ContactUsForm from './ContactUsForm';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import { contactUsMessage } from '../../util/api';

import contactImg from '../../assets/contact-bg.jpg';

const getInitialValues = props => {
  // Only render current search if full place object is available in the URL params
  // TODO bounds are missing - those need to be queried directly from Google Places
  //   const languages = publicData?.languages || [];

  return {
    // languages,
  };
};

const ContactUsPage = props => {
  // State is needed since LocationAutocompleteInput doesn't have internal state
  // and therefore re-rendering would overwrite the values during XHR call.
  const [state, setState] = useState({ initialValues: getInitialValues(props) });
  const {
    className,
    rootClassName,
    disabled,
    ready,
    onSubmit,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Page>
      <TopbarContainer />
      <div className={css.ContactWrapper}>
        <div className={css.contactFormSec}>
          <div className={css.contactForm}>
            <div className={css.formHead}>
              <H3 as="h1">
                <FormattedMessage id="ContactUsForm.title" />
              </H3>
              <p>
                Love to hear from you,
              </p>
              <p>Get in touch &#128075;</p>
            </div>
            <ContactUsForm
              className={css.form}
              initialValues={state.initialValues}
              onSubmit={values => {
                contactUsMessage(values);
                // Save the initialValues to state
                // LocationAutocompleteInput doesn't have internal state
                // and therefore re-rendering would overwrite the values during XHR call.
                // setState({
                //   initialValues: {},
                // });
                // onSubmit(updateValues);
              }}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              autoFocus
            />
          </div>
          <div className={css.contactImg}>
            <img src={contactImg} alt="img" />
          </div>
        </div>
      </div>

      <FooterComponent />
    </Page>
  );
};

const { func, object, string, bool } = PropTypes;

ContactUsPage.defaultProps = {
  className: null,
  rootClassName: null,
};

ContactUsPage.propTypes = {
  className: string,
  rootClassName: string,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default ContactUsPage;
