import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconLogout = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.70825 3.64998C3.83742 2.14998 4.60825 1.53748 6.29575 1.53748H6.34992C8.21242 1.53748 8.95825 2.28331 8.95825 4.14581V6.86248C8.95825 8.72498 8.21242 9.47081 6.34992 9.47081H6.29575C4.62075 9.47081 3.84992 8.86664 3.71242 7.39164"
        stroke="#98A8B7"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.24997 5.5H1.5083"
        stroke="#98A8B7"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.43758 4.10419L1.04175 5.50002L2.43758 6.89585"
        stroke="#98A8B7"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconLogout.defaultProps = { className: null };

IconLogout.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLogout;
