import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { number, shape } from 'prop-types';
import classNames from 'classnames';

// Import configs and util modules
import { FormattedMessage, injectIntl, intlShape, useIntl } from '../../util/reactIntl';
// Import shared components
import {
  Button,
  FieldMultiSelect,
  FieldSelect,
  FieldTextInput,
  H3,
  LayoutWrapperDashboardSideNav,
  Modal,
  Page,
} from '../../components';

// Import modules from this directory
import css from './Portfolio.module.css';
import PortfolioForm from './PortfolioForm';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import { withViewport } from '../../util/uiHelpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import IconToggleMenu from '../../components/IconToggleMenu/IconToggleMenu';
import IconCross from '../../components/IconCross/IconCross';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import * as validators from '../../util/validators';
import IconTrash from '../../components/IconTrash/IconTrash';
import IconEditWork from '../../components/IconEditWork/IconEditWork';
import PortfolioUpdateForm from './PortfolioUpdateForm';

const getInitialValues = props => {
  const { currentUser } = props;
  const { publicData } = currentUser?.attributes?.profile || {};
  const name = publicData?.portfolio?.name || '';
  const typeOfCare = publicData?.portfolio?.typeOfCare || [];
  const services = publicData?.portfolio?.services || '';
  const months = publicData?.portfolio?.months || '';
  return {
    name,
    typeOfCare,
    services,
    months,
  };
};

const PortfolioPageComponent = props => {
  // State is needed since LocationAutocompleteInput doesn't have internal state
  // and therefore re-rendering would overwrite the values during XHR call.
  const [state, setState] = useState({ initialValues: getInitialValues(props) });
  const {
    className,
    rootClassName,
    disabled,
    ready,
    onSubmit,
    panelUpdated,
    updateInProgress,
    errors,
    viewport,
    scrollingDisabled,
    onManageDisableScrolling,
    currentUser,
    onUpdateProfile,
  } = props;

  const classes = classNames(rootClassName || css.root, className, css.mainContent);
  const isMobileLayout = viewport?.width && viewport?.width < 768;
  const [show, setShow] = useState(false);
  const portfolio = currentUser?.attributes?.profile?.publicData?.portfolio || [];
  const [open, setOpen] = useState(false);
  const [selectClient, setSelectedClient] = useState({});
  const [index, setIndex] = useState(0);
  const sidebarRef = useRef(null);
  const handleClickOutside = event => {
    if (event.target !== sidebarRef.current) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleDelete = i => {
    portfolio.splice(i, 1);
    onUpdateProfile({ publicData: { portfolio } });
  };

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <TopbarContainer />
      <div className={css.editListingPageWrapper}>
        <div
          ref={sidebarRef}
          className={classNames(css.sidebar, show && isMobileLayout ? css.sidebarMobile : null)}
        >
          <LayoutWrapperDashboardSideNav currentTab="Portfolio" />
        </div>
        <div className={classes}>
          <div className={css.pageTitle}>
            Portfolio
            <span onClick={() => setShow(!show)}>
              {!show ? (
                <IconToggleMenu className={css.menuIcon} />
              ) : (
                <IconCross rootClassName={css.closeIcon} />
              )}
            </span>
          </div>
          <div className={css.portfolioContent}>
            <div className={css.sectionHead}>
              <FormattedMessage id="PortfolioForm.title1" />
            </div>
            <div className={css.portfolioHistory}>
              {portfolio.length > 0
                ? portfolio.map((client, i) => {
                  return (
                    <div className={css.workBlock} key={client.name + i}>
                      <div className={css.workRow}>
                        <strong>
                          <FormattedMessage id="PortfolioForm.name" />
                        </strong>
                        {client.name}
                      </div>
                      <div className={css.workRow}>
                        <strong>
                          <FormattedMessage id="PortfolioForm.typeOfCare" />
                        </strong>
                        {client.typeOfCare.map(object => object.label).join(", ")}
                      </div>
                      <div className={css.workRow}>
                        <strong>
                          <FormattedMessage id="PortfolioForm.services" />
                        </strong>
                        {client.services}
                      </div>
                      <div className={css.workRow}>
                        <strong>
                          <FormattedMessage id="PortfolioForm.months" />
                        </strong>
                        {client.months}
                        <FormattedMessage id="PortfolioForm.labelMonths" />
                      </div>
                      <div className={css.workRow}>
                        <Button
                          type="button"
                          className={css.editBTn}
                          onClick={() => {
                            setOpen(true);
                            setSelectedClient(client);
                            setIndex(i);
                          }}
                        >
                          <IconEditWork /> Edit
                        </Button>
                        <Modal
                          isOpen={open}
                          onClose={() => setOpen(false)}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          onManageDisableScrolling={onManageDisableScrolling}
                          usePortal
                          lightCloseButton={false}
                        >
                          <div className={css.modalTitle}>
                            <FormattedMessage id="PortfolioForm.modelTitle" />
                          </div>
                          <div className={css.editPortfolioForm}>
                            <PortfolioUpdateForm
                              className={css.portfolioForm}
                              initialValues={selectClient}
                              onSubmit={values => {
                                portfolio.splice(index, 1, values);
                                onUpdateProfile({ publicData: { portfolio } });
                                setOpen(false);
                              }}
                              disabled={disabled}
                              ready={ready}
                              updated={panelUpdated}
                              updateInProgress={updateInProgress}
                              fetchErrors={errors}
                              autoFocus
                            />
                          </div>
                        </Modal>
                        <Button
                          className={css.deleteBtn}
                          type="button"
                          onClick={() => handleDelete(i)}
                        >
                          <IconTrash />
                          Delete
                        </Button>
                      </div>
                    </div>
                  );
                })
                : <div>
                  <p>No previous work</p>
                </div>}
            </div>
            <div className={css.sectionHead}>
              <FormattedMessage id="PortfolioForm.title2" />
            </div>
            <PortfolioForm
              className={css.portfolioForm}
              initialValues={state.initialValues}
              onSubmit={values => {
                const { name, typeOfCare, services, months } = values;
                const updateValues = {
                  publicData: {
                    portfolio: [...portfolio, { name, typeOfCare, services, months }],
                  },
                };
                // Save the initialValues to state
                // LocationAutocompleteInput doesn't have internal state
                // and therefore re-rendering would overwrite the values during XHR call.
                setState({
                  initialValues: {
                    name,
                    services,
                    months,
                    typeOfCare
                  },
                });
                onUpdateProfile(updateValues);
              }}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              autoFocus
            />
          </div>
        </div>
      </div>
      <FooterComponent />
    </Page>
  );
};

const { func, object, string, bool } = PropTypes;

PortfolioPageComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

PortfolioPageComponent.propTypes = {
  className: string,
  rootClassName: string,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};
const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const PortfolioPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withViewport
)(PortfolioPageComponent);

export default PortfolioPage;
