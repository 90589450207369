import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { LISTING_STATE_PUBLISHED, propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';
import IconMenu from '../../IconMenu/IconMenu';
import IconLogin from '../../IconLogin/IconLogin';
import IconLogout from '../../IconLogout/IconLogout';
import IconHome from '../../IconHome/IconHome';
import IconDashboard from '../../IconDashboard/IconDashboard';
import IconBlog from '../../IconBlog/IconBlog';
import IconCareProvider from '../../IconCareProvider/IconCareProvider';
import IconFindClients from '../../IconFindClients/IconFindClients';
import IconAboutUs from '../../IconAboutUs/IconAboutUs';
import IconContactUs from '../../IconContactUs/IconContactUs';
import IconHowWorks from '../../IconHowWorks/IconHowWorks';
import IconViewProfile from '../../IconViewProfile/IconViewProfile';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../../util/urlHelpers';
import { useSelector } from 'react-redux';
import IconUserProfile from '../../IconUserProfile/IconUserProfile';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    location,
    params,
  } = props;
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const id = currentUser?.id && currentUser?.id?.uuid;
  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const classes = classNames(rootClassName || css.root, className);
  const currentUserListing = useSelector(state => state?.user?.currentUserListing);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={classNames(css.inboxLink, css.postLoginLink)}
      name="ComingSoonPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <IconMenu />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="Avatar">
          <div className={css.dropDownHead}>
            <NamedLink name="ComingSoonPage">
              <Avatar className={css.avatar} user={currentUser} disableProfileLink />
            </NamedLink>
            <h4>{currentUser?.attributes?.profile?.displayName}</h4>{' '}
            <label className={css.toggleLabel}>
              <input onClick={onLogout} type="checkbox" />
              <span className={css.back}>
                <span className={css.toggle}></span>
                <span className={classNames(css.label, css.on)}>
                  <IconLogin />
                  <FormattedMessage id="TopbarDesktop.login" />
                </span>
                <span className={classNames(css.label, css.off)}>
                  <IconLogout />
                  <FormattedMessage id="TopbarDesktop.logout" />
                </span>
              </span>
            </label>
          </div>
        </MenuItem>

        <MenuItem key="Home">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="LandingPage"
          >
            <IconHome />
            <FormattedMessage id="TopbarDesktop.home" />
          </NamedLink>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ComingSoonPage"
          >
            <IconDashboard />
            <FormattedMessage id="TopbarDesktop.dashboard" />
          </NamedLink>
          {currentUser?.id?.uuid ? (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ComingSoonPage"
              params={{ id: id }}
            >
              <IconUserProfile />
              <FormattedMessage id="TopbarDesktop.viewProfile" />
            </NamedLink>
          ) : null}
          {/* <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="BlogPage"
          >
            <IconBlog />
            <FormattedMessage id="TopbarDesktop.blog" />
          </NamedLink> */}
        </MenuItem>

        <MenuItem key="FindClients">
          {currentUserHasListings &&
          currentUserListing?.attributes?.state === LISTING_STATE_PUBLISHED ? (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ComingSoonPage"
              params={{
                id: currentUserListing?.id?.uuid,
                slug: createSlug(currentUserListing?.attributes?.title),
                type: LISTING_PAGE_PARAM_TYPE_EDIT,
                tab: 'location',
              }}
            >
              <IconFindClients />
              <FormattedMessage id="TopbarDesktop.services" />
            </NamedLink>
          ) : currentUserListing &&
            currentUserListing?.attributes?.state === LISTING_PAGE_PARAM_TYPE_DRAFT ? (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ComingSoonPage"
              params={{
                id: currentUserListing?.id?.uuid,
                slug: createSlug(currentUserListing?.attributes?.title),
                type: LISTING_PAGE_PARAM_TYPE_DRAFT,
                tab: 'location',
              }}
            >
              <IconFindClients />
              <FormattedMessage id="TopbarDesktop.services" />
            </NamedLink>
          ) : (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
              name="ComingSoonPage"
            >
              <IconFindClients />
              <FormattedMessage id="TopbarDesktop.services" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="about">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ComingSoonPage"
            params={{ pageId: 'about' }}
          >
            <IconAboutUs />
            <FormattedMessage id="TopbarDesktop.aboutUs" />
          </NamedLink>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ContactUsPage"
          >
            <IconContactUs />
            <FormattedMessage id="TopbarDesktop.contactUs" />
          </NamedLink>
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ComingSoonPage"
            params={{ pageId: 'how-its-work' }}
          >
            <IconHowWorks />
            <FormattedMessage id="TopbarDesktop.howItswork" />
          </NamedLink>
        </MenuItem>
        {/*<MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem> */}
      </MenuContent>
    </Menu>
  ) : null;

  const signupCustomerLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      to={{ state: { type: 'customer' } }}
      className={css.signupCustomerLink}
    >
      <FormattedMessage id="TopbarDesktop.findSeniorCare" />
    </NamedLink>
  );

  const signupProviderLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink
      name="SignupPage"
      to={{ state: { type: 'provider' } }}
      className={css.signupProviderLink}
    >
      <FormattedMessage id="TopbarDesktop.findClients" />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.contentWidth}>
        <div className={css.leftNav}>
          <LinkedLogo
            className={css.logoLink}
            format="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
          />
        </div>
        <div className={css.rightNav}>
          {/* {search} */}
          {/* {currentUser &&
          currentUser?.attributes?.profile?.publicData?.userType !== ROLE_CUSTOMER ? (
            <NamedLink
              className={classNames(css.createListingLink, css.postLoginLink)}
              name="NewListingPage"
            >
              <FormattedMessage id="TopbarDesktop.createListing" />
            </NamedLink>
          ) : null} */}
          {inboxLink}
          {signupCustomerLink}
          {signupProviderLink}
          {isAuthenticated ? (
            <span className={css.doropDownMenu}>{profileMenu}</span>
          ) : (
            <NamedLink name="LoginPage" className={css.loginLink}>
              <FormattedMessage id="TopbarDesktop.login" />
            </NamedLink>
          )}
        </div>
      </div>
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
