/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'kindOfCareLabel',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: "going-to-outings-hobbies", label: "Going to outings Hobbies", },
      { option: "fun-activies", label: "Fun activies", },
      { option: "digital", label: "Digital Companionship", },
      { option: "inPerson", label: "In-Person Companionship", },
      { option: "conversations-with-like-minded-people", label: "Conversations with like-minded people", },
      { option: "bathing-feeding", label: "Bathing Feeding", },
      { option: "administer-medication", label: "Administer medication", },
      { option: "massages", label: "Massages", },
      { option: "personal-driver", label: "Personal driver", },
      { option: "wheelchair-access-van", label: "Wheelchair access van", },
      { option: "adult-with-disabilities", label: "Adult with disabilities", },
      { option: "tube-feeding", label: "Tube feeding", },
      { option: "bowel-care", label: "Bowel care", },
      { option: "suction", label: "Suction", },
      { option: "dressing-wounds", label: "Dressing wounds", },
      { option: "injections", label: "Injections", },
      { option: "dementia-care", label: "Dementia care", },
      { option: "post-surgery-care", label: "Post-surgery care", },
      { option: "palliative-care", label: "Palliative care", },
      { option: "hospice-visits-and-care", label: "Hospice visits and care", },
      { option: "housekeeping/cleaning", label: "Housekeeping/cleaning", },
      { option: "laundry", label: "Laundry", },
      { option: "preparing-meals", label: "Preparing meals", },
      { option: "errands", label: "Errands", },
      { option: "groceries", label: "Groceries", },
      { option: "psychologist", label: "Psychologist", },
      { option: "social-worker", label: "Social worker", },
      { option: "joining-medical-visits", label: "Joining medical visits", },
      { option: "providing-clarity", label: "Providing clarity", },
      { option: "helping-with-bureaucracy", label: "Helping with bureaucracy", },
      { option: "answering-questions", label: "Answering questions", },
      { option: "booking-appointments", label: "Booking appointments", },
      { option: "occupational-therapy", label: "Occupational therapy", },
      { option: "physical-therapy", label: "Physical therapy", },
      { option: "exercise", label: "Exercise", },
      { option: "cognitive-stimulation", label: "Cognitive stimulation", },
      { option: "games-and-activities", label: "Games and activities", },
      { option: "helping-getting-around", label: "Helping getting around", },
      { option: "household-adjustments", label: "Household adjustments", },
      { option: "device-setup", label: "Device setup", },
      { option: "internet-and-browsing", label: "Internet and browsing", },
      { option: "troubleshooting", label: "Troubleshooting", },
      { option: "data-management", label: "Data management", },
      { option: "technology-education", label: "Technology education", },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      searchMode: 'has_any',
      label: 'Care',
      group: 'primary',
    },
    showConfig: {
      label: 'Care',
      isDetail: true,
    },
    saveConfig: {
      label: 'Care',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a care.',
    },
  },
  {
    key: 'typeOfCareLabel',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "full-time", label: "Full-time" },
      { option: "part-time", label: "Part-time" },
      { option: "live-in", label: "Live-in" },
      { option: "live-out", label: "Live-out" },
      { option: "long-term", label: "Long-term" },
      { option: "short-term", label: "Short-term" },
      { option: "occasional", label: "Occasional" },
      { option: "start-asap", label: "Start-ASAP" },
      { option: "summer-only", label: "Summer-only" },
      { option: "tbd", label: "TBD" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Type of Care',
      searchMode: 'has_any',
      group: 'secondary',
    },
    showConfig: {
      label: 'Type of Care',
      isDetail: true,
    },
    saveConfig: {
      label: 'Type of Care',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a type of Care.',
    },
  },
  {
    key: 'qualificationsLabel',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "access to vehicle", label: "Access to vehicle" },
      { option: "drivers-license", label: "Drivers License" },
      { option: "CPR", label: "CPR" },
      { option: "first-aid", label: "First Aid" },
      { option: "sponsorship-visa", label: "Sponsorship Visa" },
      { option: "registered-Nurse(RN)", label: "Registered Nurse (RN)" },
      { option: "advanced-practical-registered-nurse", label: "Advanced practical registered nurse" },
      { option: "certified-nursing-assistant(CNA)", label: "Certified nursing assistant (CNA)" },
      { option: "licensed-practical-nurse(LPN/LVN)", label: "Licensed practical nurse (LPN/LVN)" },
      { option: "medical-assistant", label: "Medical assistant" },
      { option: "nurse-practitioner", label: "Nurse practitioner" },
      { option: "occupational-therapist (B.Sc)", label: "Occupational therapist (B.Sc)" },
      { option: "social-worker-(B.Sc)", label: "Social worker (B.Sc)" },
      { option: "social-worker-(M.Sc.)", label: "Social worker (M.Sc.)" },
      { option: "physical-therapist-(B.Sc.)", label: "Physical therapist (B.Sc.)" },
      { option: "others", label: "Others" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Qualifications',
      searchMode: 'has_any',
      group: 'secondary',
    },
    showConfig: {
      label: 'Qualifications',
      isDetail: true,
    },
    saveConfig: {
      label: 'Qualifications',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a qualification.',
    },
  },
  {
    key: 'languagesLabel',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "english", label: "English" },
      { option: "french", label: "French" },
      { option: "spanish", label: "Spanish" },
      { option: "russian", label: "Russian" },
      { option: "cantonese", label: "Cantonese" },
      { option: "mandarin", label: "Mandarin" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Languages',
      searchMode: 'has_any',
      group: 'secondary',
    },
    showConfig: {
      label: 'Languages',
    },
    saveConfig: {
      label: 'Languages',
      placeholderMessage: 'Select an option…',
      isRequired: false,
    },
  },
  {
    key: 'days',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "mon", label: "Monday" },
      { option: "tue", label: "Tuesday" },
      { option: "wed", label: "Wednesday" },
      { option: "thu", label: "Thursday" },
      { option: "fri", label: "Friday" },
      { option: "sat", label: "Saturday" },
      { option: "sun", label: "Sunday" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Days',
      searchMode: 'has_all',
      group: 'secondary',
    },
    showConfig: {
      label: 'Days',
      isDetail: true,
    },
    saveConfig: {
      label: 'Days',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select the days.',
    },
  },
  {
    key: 'experience',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: "0-1", label: "0-1" },
      { option: "1-3", label: "1-3" },
      { option: "3-5", label: "3-5" },
      { option: "5-8", label: "5-8" },
      { option: "8-10", label: "8-10" },
      { option: "10+", label: "10+" },
    ],
    filterConfig: {
      indexForSearch: true,
      label: 'Years of experience',
      searchMode: 'has_any',
      group: 'secondary',
    },
    showConfig: {
      label: 'Years of experience',
      isDetail: true,
    },
    saveConfig: {
      label: 'Years of experience',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select experience.',
    },
  },
  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
