import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconCareProvider = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 3.875C5.69755 3.875 6.12678 3.69721 6.44324 3.38074C6.75971 3.06428 6.9375 2.63505 6.9375 2.1875C6.9375 1.73995 6.75971 1.31072 6.44324 0.994257C6.12678 0.67779 5.69755 0.5 5.25 0.5C4.80245 0.5 4.37323 0.67779 4.05676 0.994257C3.74029 1.31072 3.5625 1.73995 3.5625 2.1875C3.5625 2.63505 3.74029 3.06428 4.05676 3.38074C4.37323 3.69721 4.80245 3.875 5.25 3.875ZM10.125 8.375C9.82663 8.375 9.54048 8.49353 9.32951 8.7045C9.11853 8.91548 9 9.20163 9 9.5V9.62862C9 9.72808 9.03951 9.82346 9.10983 9.89379C9.18016 9.96412 9.27554 10.0036 9.375 10.0036C9.47446 10.0036 9.56984 9.96412 9.64017 9.89379C9.71049 9.82346 9.75 9.72808 9.75 9.62862V9.5C9.75 9.40054 9.78951 9.30516 9.85983 9.23483C9.93016 9.16451 10.0255 9.125 10.125 9.125C10.2245 9.125 10.3198 9.16451 10.3902 9.23483C10.4605 9.30516 10.5 9.40054 10.5 9.5V15.1047C10.5 15.2042 10.5395 15.2996 10.6098 15.3699C10.6802 15.4402 10.7755 15.4797 10.875 15.4797C10.9745 15.4797 11.0698 15.4402 11.1402 15.3699C11.2105 15.2996 11.25 15.2042 11.25 15.1047V9.5C11.25 9.20163 11.1315 8.91548 10.9205 8.7045C10.7095 8.49353 10.4234 8.375 10.125 8.375ZM8.14087 6.56713L7.5 6.28738L8.09812 6.54125L8.14087 6.56713ZM8.60063 6.96537C8.47024 6.80833 8.31492 6.67378 8.14087 6.56713L10.5 7.598V7.562V7.56275C10.5356 7.97525 10.2169 8.33787 9.78788 8.37275C9.36263 8.4065 8.98838 8.105 8.9475 7.69738V7.69662C8.93628 7.63047 8.91998 7.56528 8.89875 7.50163C8.83304 7.30613 8.73199 7.12437 8.60063 6.96537Z"
        fill="#155EBD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4999 7.56162L8.09805 6.54163C7.91004 6.4322 7.70911 6.34667 7.49993 6.287V14.75C7.49985 14.9424 7.42585 15.1274 7.29321 15.2668C7.16057 15.4061 6.97944 15.4892 6.78729 15.4988C6.59513 15.5084 6.40664 15.4437 6.26079 15.3183C6.11494 15.1928 6.02289 15.0161 6.00368 14.8246L5.62868 11.0746C5.62619 11.0498 5.62494 11.0249 5.62493 11H4.87493C4.87493 11.0248 4.8738 11.0499 4.87118 11.0746L4.49618 14.8246C4.47696 15.0161 4.38491 15.1928 4.23906 15.3183C4.09322 15.4437 3.90472 15.5084 3.71257 15.4988C3.52041 15.4892 3.33928 15.4061 3.20664 15.2668C3.074 15.1274 3 14.9424 2.99993 14.75V9.46963C2.3703 9.386 1.84118 9.13287 1.44893 8.74775C1.22418 8.52747 1.04637 8.26396 0.926235 7.9731C0.8061 7.68223 0.746124 7.37005 0.749926 7.05538C0.753039 6.74162 0.818267 6.43158 0.941845 6.14317C1.06542 5.85476 1.2449 5.59368 1.46993 5.375C1.95368 4.90288 2.6433 4.625 3.47055 4.625H6.21555C8.08043 4.625 9.19455 5.27075 9.82305 6.03463C10.0702 6.33428 10.2601 6.67686 10.3833 7.04525C10.4381 7.21025 10.4808 7.38012 10.4992 7.55262V7.55825L10.4999 7.5605V7.56162ZM2.56155 7.69738C2.65793 7.79188 2.79818 7.88337 2.99993 7.94037V6.18875C2.81243 6.24575 2.67593 6.33463 2.5788 6.42913C2.4938 6.51282 2.42619 6.61249 2.37985 6.72242C2.33351 6.83234 2.30937 6.95034 2.3088 7.06962C2.30655 7.30962 2.3958 7.53463 2.56155 7.69738Z"
        fill="#2e3599"
      />
    </svg>
  );
};

IconCareProvider.defaultProps = { className: null };

IconCareProvider.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCareProvider;
