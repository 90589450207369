import React from 'react';
import { H1 } from '../../components';
import css from './DashboardForm.module.css';

const DashboardForm = () => {
  return (
    <div className={css.dashboardForm}>
      <H1>Coming Soon...</H1>
    </div>
  );
};

export default DashboardForm;
