import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconDashboard2 = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="9.5"
        height="5.5"
        rx="1.25"
        stroke="#2E3599"
        strokeWidth="1.5"
      />
      <rect
        x="0.75"
        y="9.75"
        width="9.5"
        height="12.5"
        rx="1.25"
        stroke="#2E3599"
        strokeWidth="1.5"
      />
      <rect
        x="13.75"
        y="16.75"
        width="9.5"
        height="5.5"
        rx="1.25"
        stroke="#2E3599"
        strokeWidth="1.5"
      />
      <path
        d="M15 0.75H22C22.6904 0.75 23.25 1.30964 23.25 2V12C23.25 12.6904 22.6904 13.25 22 13.25H15C14.3096 13.25 13.75 12.6904 13.75 12V2C13.75 1.30964 14.3096 0.75 15 0.75Z"
        stroke="#2E3599"
        strokeWidth="1.5"
      />
      <rect x="13" width="11" height="14" rx="2" fill="#19CCFF" />
    </svg>
  );
};

IconDashboard2.defaultProps = { className: null };

IconDashboard2.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDashboard2;
