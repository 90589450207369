import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconAboutUs = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 14.6666C11.6666 14.6666 14.6666 11.6666 14.6666 7.99998C14.6666 4.33331 11.6666 1.33331 7.99992 1.33331C4.33325 1.33331 1.33325 4.33331 1.33325 7.99998C1.33325 11.6666 4.33325 14.6666 7.99992 14.6666Z"
        stroke="#2e3599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 5.33331V8.66665"
        stroke="#155EBD"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99634 10.6667H8.00233"
        stroke="#155EBD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconAboutUs.defaultProps = { className: null };

IconAboutUs.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAboutUs;
