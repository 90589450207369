import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconViewProfile = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1801 19.1697C18.0751 19.1547 17.9401 19.1547 17.8201 19.1697C15.1801 19.0797 13.0801 16.9197 13.0801 14.2647C13.0801 11.5497 15.2701 9.34473 18.0001 9.34473C20.7151 9.34473 22.9201 11.5497 22.9201 14.2647C22.9051 16.9197 20.8201 19.0797 18.1801 19.1697Z"
        stroke="#19CCFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1101 29.0698C25.4401 31.5148 21.9001 32.9998 18.0001 32.9998C14.1001 32.9998 10.5601 31.5148 7.89014 29.0698C8.04014 27.6598 8.94014 26.2798 10.5451 25.1998C14.6551 22.4698 21.3751 22.4698 25.4551 25.1998C27.0601 26.2798 27.9601 27.6598 28.1101 29.0698Z"
        stroke="#2E3599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
        stroke="#2E3599"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconViewProfile.defaultProps = { className: null };

IconViewProfile.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconViewProfile;
