import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconHome = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.26175 1.65666L2.11758 4.10666C1.59258 4.51499 1.16675 5.38416 1.16675 6.04333V10.3658C1.16675 11.7192 2.26925 12.8275 3.62258 12.8275H10.3776C11.7309 12.8275 12.8334 11.7192 12.8334 10.3717V6.12499C12.8334 5.41916 12.3609 4.51499 11.7834 4.11249L8.17841 1.58666C7.36175 1.01499 6.04925 1.04416 5.26175 1.65666Z"
        stroke="#2e3599"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 10.4941V8.74414" stroke="#155EBD" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

IconHome.defaultProps = { className: null };

IconHome.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHome;
