import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconUserProfile = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="12"
      height="13"
      viewBox="0 0 366 407"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M314.215 276.725C310.977 272.776 306.375 270.186 301.318 269.469C296.262 268.752 291.12 269.96 286.913 272.854C256.339 293.881 220.106 305.138 183 305.138C145.894 305.138 109.661 293.881 79.0875 272.854C74.8795 269.96 69.7383 268.752 64.6818 269.469C59.6254 270.186 55.0227 272.776 51.785 276.725C31.2438 302.091 20.0246 333.735 20 366.375C20 371.779 22.1466 376.961 25.9677 380.782C29.7888 384.603 34.9712 386.75 40.375 386.75H325.625C331.029 386.75 336.211 384.603 340.032 380.782C343.853 376.961 346 371.779 346 366.375C345.975 333.735 334.756 302.091 314.215 276.725Z"
        stroke="#2E3599"
        strokeWidth="26"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183 223.75C239.264 223.75 284.875 178.139 284.875 121.875C284.875 65.611 239.264 20 183 20C126.736 20 81.125 65.611 81.125 121.875C81.125 178.139 126.736 223.75 183 223.75Z"
        stroke="#2E3599"
        strokeWidth="26"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconUserProfile.defaultProps = { className: null };

IconUserProfile.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconUserProfile;
