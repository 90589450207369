import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconBlog = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 11H1V1H17V11Z" stroke="#2e3599" />
      <path
        d="M3.5 9H7.5C7.77614 9 8 8.77614 8 8.5V3.5C8 3.22386 7.77614 3 7.5 3H3.5C3.22386 3 3 3.22386 3 3.5V8.5C3 8.77614 3.22386 9 3.5 9Z"
        fill="#155EBD"
      />
      <path
        d="M10.5 5H14.5C14.7761 5 15 4.77614 15 4.5V3.5C15 3.22386 14.7761 3 14.5 3H10.5C10.2239 3 10 3.22386 10 3.5V4.5C10 4.77614 10.2239 5 10.5 5Z"
        fill="#2e3599"
      />
      <path
        d="M10.5 9H12.5C12.7761 9 13 8.77614 13 8.5V7.5C13 7.22386 12.7761 7 12.5 7H10.5C10.2239 7 10 7.22386 10 7.5V8.5C10 8.77614 10.2239 9 10.5 9Z"
        fill="#2e3599"
      />
    </svg>
  );
};

IconBlog.defaultProps = { className: null };

IconBlog.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBlog;
