import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

const IconHowWorks = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);

  return (
    <svg
      className={classes}
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33317 1.16669H2.33317C1.68884 1.16669 1.1665 1.68902 1.1665 2.33335V9.33335C1.1665 9.97769 1.68884 10.5 2.33317 10.5H9.33317C9.9775 10.5 10.4998 9.97769 10.4998 9.33335V2.33335C10.4998 1.68902 9.9775 1.16669 9.33317 1.16669Z"
        stroke="#2e3599"
      />
      <path
        d="M4.95833 11.6667H3.5C3.5 12.311 4.02233 12.8334 4.66667 12.8334H4.95833C5.11942 12.8334 5.25 12.7028 5.25 12.5417V11.9584C5.25 11.7973 5.11942 11.6667 4.95833 11.6667Z"
        fill="#155EBD"
      />
      <path
        d="M7.2915 11.6667H6.70817C6.54709 11.6667 6.4165 11.7973 6.4165 11.9584V12.5417C6.4165 12.7028 6.54709 12.8334 6.70817 12.8334H7.2915C7.45259 12.8334 7.58317 12.7028 7.58317 12.5417V11.9584C7.58317 11.7973 7.45259 11.6667 7.2915 11.6667Z"
        fill="#155EBD"
      />
      <path
        d="M9.625 11.6667H9.04167C8.88058 11.6667 8.75 11.7973 8.75 11.9584V12.5417C8.75 12.7028 8.88058 12.8334 9.04167 12.8334H9.625C9.78608 12.8334 9.91667 12.7028 9.91667 12.5417V11.9584C9.91667 11.7973 9.78608 11.6667 9.625 11.6667Z"
        fill="#155EBD"
      />
      <path
        d="M12.5414 11.0833H11.6664C11.576 11.0823 11.4908 11.126 11.4389 11.2C11.3701 11.2863 11.2918 11.3646 11.2056 11.4333C11.1276 11.4851 11.0814 11.5731 11.0831 11.6667V12.565C11.0835 12.6138 11.1074 12.6594 11.1472 12.6875C11.1877 12.7073 11.2351 12.7073 11.2756 12.6875C11.9408 12.4526 12.464 11.9294 12.6989 11.2642C12.7187 11.2237 12.7187 11.1763 12.6989 11.1358C12.6647 11.095 12.6115 11.075 12.5589 11.0833H12.5414Z"
        fill="#155EBD"
      />
      <path
        d="M11.6665 3.5V4.95833C11.6665 5.11942 11.7971 5.25 11.9582 5.25H12.5415C12.7026 5.25 12.8332 5.11942 12.8332 4.95833V4.66667C12.8332 4.02233 12.3108 3.5 11.6665 3.5Z"
        fill="#155EBD"
      />
      <path
        d="M12.5415 6.41669H11.9582C11.7971 6.41669 11.6665 6.54727 11.6665 6.70835V7.29169C11.6665 7.45277 11.7971 7.58335 11.9582 7.58335H12.5415C12.7026 7.58335 12.8332 7.45277 12.8332 7.29169V6.70835C12.8332 6.54727 12.7026 6.41669 12.5415 6.41669Z"
        fill="#155EBD"
      />
      <path
        d="M12.5415 8.75H11.9582C11.7971 8.75 11.6665 8.88058 11.6665 9.04167V9.625C11.6665 9.78608 11.7971 9.91667 11.9582 9.91667H12.5415C12.7026 9.91667 12.8332 9.78608 12.8332 9.625V9.04167C12.8332 8.88058 12.7026 8.75 12.5415 8.75Z"
        fill="#155EBD"
      />
    </svg>
  );
};

IconHowWorks.defaultProps = { className: null };

IconHowWorks.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHowWorks;
