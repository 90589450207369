import React from 'react';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import css from './FallbackPage.module.css';
import FooterComponent from '../FooterContainer/FooterContainer';

const ComingSoonPage = () => {
  return (
    <>
      <TopbarContainer />
      <section className={css.root}>
        <div className={css.content}>
          <h2>Coming Soon</h2>
          <p>
            Our website is currently under construction
            <br />
            We're working hard to bring you an amazing experience. 
          </p>
        </div>
      </section>
      <FooterComponent />
    </>
  );
};

export default ComingSoonPage;
